<template>
<div class="home">
    <div class="home-top">
        <SearchTop />
        <Swiper />
    </div>
    <div class="class-nav">
        <router-link to="/emporiumList/12">
            <span class="specil-tag">热</span>
            <div class="logo-wrap">
                <img src="../assets/shop_navIcon01.png" alt="" />
            </div>
            <div class="class-navtext">亲子活动</div>
        </router-link>
        <router-link to="/emporiumList/13">
            <div class="logo-wrap">
                <img src="../assets/shop_navIcon02.png" alt="" />
            </div>
            <div class="class-navtext">活动套装</div>
        </router-link>
        <router-link to="/emporiumList/14">
            <span class="specil-tag">必看</span>
            <div class="logo-wrap">
                <img src="../assets/shop_navIcon03.png" alt="" />
            </div>
            <div class="class-navtext">扶农助农</div>
        </router-link>
        <router-link to="/emporiumList/15">
            <span class="specil-tag">新</span>
            <div class="logo-wrap">
                <img src="../assets/shop_navIcon04.png" alt="" />
            </div>
            <div class="class-navtext">学而有用</div>
        </router-link>
    </div>

    <!-- <NoticeLine /> -->

    <div class="actives-content-wrap">
        <div class="class-tit">
            <div class="left-tit">
                <p>社会实践打卡</p>
                <p>副標題文案</p>
                <span class="specil-tag socil-specil-tag">小類標文案</span>
            </div>
            <router-link to="" class="check-more">查看更多</router-link>
        </div>

        <div class="actives-outer">
            <div class="actives-wrap">
                <div class="actives-item" v-for="(item,index) in stryList" :key="index">
                    <router-link :to="'/emporiumDetails/'+item.Product_ID">
                        <div class="actives-img-wrap">
                            <img :src="domin+item.Product_ImageUrl" alt="active_imgs" />
                        </div>
                        <h6>{{item.Product_Name}}</h6>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="class-stu">
        <img class="stu-banner" src="../assets/ad_stand.png" alt="banner" />
    </div>

    <div class="actives-content-wrap">
        <div class="class-tit">
            <div class="left-tit">
                <p>研学打卡</p>
                <p>副標題文案</p>
                <span class="specil-tag study-specil-tag">小類標文案</span>
            </div>
            <router-link to="" class="check-more">查看更多</router-link>
        </div>

        <div class="actives-outer">
            <div class="actives-wrap">
                <div class="actives-item" v-for="(item,index) in stuList" :key="index">
                    <router-link :to="'/emporiumDetails/'+item.Product_ID">
                        <div class="actives-img-wrap">
                            <img :src="domin+item.Product_ImageUrl" alt="active_imgs" />
                        </div>
                        <h6>{{item.Product_Name}}</h6>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <div class="actives-content-wrap" style="background: none">
        <div class="class-tit">
            <div class="left-tit">
                <p>热门打卡</p>
                <p>副標題文案</p>
                <span class="specil-tag shop-specil-tag">小類標文案</span>
            </div>
            <router-link to="" class="check-more">必打卡活动</router-link>
        </div>

        <div class="actives-outer">
            <div class="actives-wrap last-actives-wrap">
                <div class="actives-item last-actives-item" v-for="(item,index) in hotList" :key="index">
                    <router-link :to="'/emporiumDetails/'+item.Product_ID">

                        <div class="actives-img-wrap">
                            <img :src="domin+item.Product_ImageUrl" alt="active_imgs" />
                        </div>
                        <h6>{{item.Product_Name}}</h6>
                    </router-link>
                </div>
                <div class="actives-item last-actives-item">
                    <div class="actives-words shop-words" style="height: calc(30vw + 26px)">
                        <router-link to="">排行前三</router-link>
                        <router-link to="">限时特惠</router-link>
                        <router-link to="">亲子玩乐</router-link>
                        <router-link to="">益智解谜</router-link>
                        <router-link to="">查看更多</router-link>
                    </div>
                </div>
                <!-- <div class="actives-item last-actives-item">
                    <wx-open-launch-weapp username="gh_cab9935f80a6">
                        <div v-is="'script'" type="text/wxtag-template">
                            <button id="weapp-btn" class="weap-btn" @click="weappClick" @launch="weappReady" @error="weappError">
                                打开“微在线鼎盛”小程序
                            </button>
                        </div>
                    </wx-open-launch-weapp>
                </div> -->
            </div>
        </div>
    </div>

    <NavBottom :checkNum="4" />
</div>
</template>

<script>
export default {
    name: "Shop",
    data() {
        return {
            pageindex: 1,
            stryList: [],
            stuList: [],
            hotList: [],

        };
    },
    mounted() {

        this.sign()
        this.getStryList()
        this.getStuList()
        this.getHotList()
    },
    methods: {
        //社会实践推荐
        getStryList() {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductmateriallist",
                data: {
                    commend: 1,
                    pageindex: this.pageindex
                },
                succ: res => {
                    console.log("study list:", res.list);
                    this.stryList = res.list;
                }
            });
        },
        //研学推荐
        getStuList() {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductmateriallist",
                data: {
                    commend: 3,
                    pageindex: this.pageindex
                },
                succ: res => {
                    console.log("study list:", res.list);
                    this.stuList = res.list;
                }
            });
        },
        //热门推荐
        getHotList() {
            this.wpost({
                url: "/Ajax/WordWelfare/getproductmateriallist",
                data: {
                    commend: 4,
                    pageindex: this.pageindex
                },
                succ: res => {
                    console.log("hot list:", res.list);
                    this.hotList = res.list;
                }
            });
        },
        weappReady() {
            console.log("wx-open-weapp is ready")
        },
        weappError(err) {
            console.log("wx-open-weapp is error", err)
        },
        weappClick() {
            console.log("wx-open-weapp is click")
        },
        sign() {
            this.wpost({
                url: "/Ajax/Word/getjssignature",
                data: {
                    url: location.href,
                },
                succ: (res) => {
                    console.log("href", location.href);
                    console.log("签名", res);

                    wx.config({
                        // debug: true,
                        appId: res.appId,
                        timestamp: res.timestamp,
                        nonceStr: res.nonce,
                        signature: res.signature,
                        jsApiList: ['openLocation'],
                        openTagList: ['wx-open-launch-weapp'],
                    });
                },
            });
        }
    },
};
</script>

<style scoped>
.home {
    text-align: center;
}

.weap-btn {
    background: none;
    outline: none;
    border: none;
    height: 33px;
    width: 100%;
}

.weap-btn:active {
    background: rgba(0, 0, 0, 0.1);
}

.actives-words {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.actives-words a {
    margin: 0 3px;
    display: block;
    font-size: 13px;
    color: #fff;
    padding: 3px 8px;
    height: 20px;
    border-radius: 10px;
    background-image: linear-gradient(to right, #4692f1, #ffc397);
}

.shop-words a {
    background-image: linear-gradient(to right, #28f5e3, #d54bfc);
}

.actives-content-wrap {
    margin: 12px 0;
    padding: 12px 0;
    background-color: #fff;
}

.actives-outer {
    overflow: hidden;
    overflow-x: scroll;
}

.actives-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.actives-item {
    flex: none;
    width: 60vw;
    margin: 6px;
    padding: 4px;
    box-shadow: 0 0 6px #ccc;
    border-radius: 6px;
    overflow: hidden;
}

.last-actives-wrap {
    flex-wrap: wrap;
}

.last-actives-item {
    box-sizing: border-box;
    width: calc(50vw - 12px);
}

.last-actives-wrap .last-actives-item:first-child {
    width: calc(100% - 12px);
}

.last-actives-wrap .last-actives-item:first-child .actives-img-wrap {
    height: 36vw;
}

.actives-item h6 {
    margin: 0;
    padding: 5px 0;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actives-img-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    height: 30vw;
}

.actives-img-wrap img {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    width: 100%;
}

.specil-tag {
    right: 0;
    background-image: linear-gradient(to right, #2df0e4, #d34dfc);
}

.specil-tag::after {
    background-image: linear-gradient(50deg, #2df0e4, #4cd2e8);
    transform: rotateZ(-15deg);
}

.socil-specil-tag::after {
    background-image: linear-gradient(50deg, #4692f1, #4692f1);
}

.home-top {
    background-color: #f5f5f5;
    background-image: url("../assets/top_bg.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    padding: 5px;
}

/* 大于五个导航样式 */
.class-nav-container {
    position: relative;
}

.class-nav-outer {
    width: calc(100% - 10px);
    overflow: hidden;
    overflow-x: auto;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px 0;
    padding-bottom: 22px;
    margin: 0 5px;
    /* scrollbar: none;
  scrollbar-color:transparent transparent; */
}

.class-nav-dot-wrap {
    bottom: 10px;
    left: 50%;
    width: 30px;
    transform: translateX(-50%);
    position: absolute;
    background-color: #ccc;
    height: 6px;
    border-radius: 3px;
}

.class-nav-dot {
    transition: transform 100ms ease;
    background-color: #4790f2;
    height: 6px;
    position: absolute;
    left: 0;
    transform: translateX(0);
    border-radius: 3px;
    width: 20px;
}

.class-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;
}

.class-nav a {
    display: block;
    width: 20%;
    flex: none;
    position: relative;
    text-decoration: none;
}

.logo-wrap {
    height: 40px;
    width: 40px;
    margin: 3px auto;
    text-align: center;
    border-radius: 50%;
    background-image: linear-gradient(to right, #606cfc, #4692f1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-wrap img {
    height: 60%;
    vertical-align: middle;
}

.class-navtext {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

/* 两个导航样式 */

.class-stu {
    margin: 10px 5px;
    border-radius: 8px;
    background-color: #fff;
    font-size: 0;
}

.stu-banner {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.tabs-name {
    display: flex;
    padding: 5px 0;
}

.tab-item {
    width: 20%;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    position: relative;
    text-decoration: none;
}

.tab-item::after {
    transition: width 300ms ease, border-color 400ms ease;
    transform-origin: center center;
    content: "";
    position: absolute;
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    bottom: -3px;
    border-bottom: 2px solid transparent;
}

.tab-item.on::after {
    width: 55px;
    border-bottom-color: #fa583f;
}

.class-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.left-tit {
    position: relative;
}

.left-tit .specil-tag {
    display: inline-block;
    border-radius: 4px;
    width: auto;
    padding: 0 3px;
    transform: translateX(calc(100% + 5px));
    background-image: linear-gradient(to right, #4692f1, #6466fe);
}

.left-tit .specil-tag::after {
    background-color: #4692f1;
    transform: skewX(138deg) rotate(100deg);
    bottom: 1px;
    left: -1px;
}

.left-tit .study-specil-tag {
    background-image: linear-gradient(to right, #4692f1, #ffc397);
}

.study-specil-tag::after {
    background-image: linear-gradient(to right, #4692f1, #4692f1);
}

.left-tit .shop-specil-tag {
    background-image: linear-gradient(to right, #28f5e3, #d54bfc);
}

.left-tit .shop-specil-tag::after {
    background-color: #28f5e3;
}

.left-tit p {
    text-align: left;
}

.left-tit p:first-child {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.left-tit p:nth-child(2) {
    font-size: 10px;
    color: #999;
}

.class-tit a {
    font-size: 11px;
    color: #999;
}

.teach-container {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}

.teach-show {
    display: flex;
}

.teach-show::after {
    content: "";
    display: block;
    clear: both;
}

.teach-show>div {
    width: 36%;
    margin: 5px 1%;
}

.teach-img-wrp img {
    width: 100%;
    object-fit: contain;
}

.teach-img-tit {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
